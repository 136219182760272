html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
}

/* remember to define focus styles! */
:focus {
  outline: 0;
}

body {
  line-height: 1;
  color: black;
  background: white;
}

ol,
ul {
  list-style: none;
}

table {
  border-collapse: separate;
  border-spacing: 0;
}

caption,
th,
td {
  text-align: left;
  font-weight: normal;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
}

blockquote,
q {
  quotes: "" "";
}

.pretty * {
  box-sizing: border-box;
}

.pretty input:not([type=checkbox]):not([type=radio]) {
  display: none;
}

.pretty {
  position: relative;
  display: inline-block;
  margin-right: 1em;
  white-space: nowrap;
  line-height: 1;
}
.pretty input {
  position: absolute;
  left: 0;
  top: 0;
  min-width: 1em;
  width: 100%;
  height: 100%;
  z-index: 2;
  opacity: 0;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
.pretty .state label {
  position: initial;
  display: inline-block;
  font-weight: normal;
  margin: 0;
  text-indent: 1.5em;
  min-width: calc(1em + 2px);
}
.pretty .state label:before, .pretty .state label:after {
  content: "";
  width: calc(1em + 2px);
  height: calc(1em + 2px);
  display: block;
  box-sizing: border-box;
  border-radius: 0;
  border: 1px solid transparent;
  z-index: 0;
  position: absolute;
  left: 0;
  top: calc((0% - (100% - 1em)) - 8%);
  background-color: transparent;
}
.pretty .state label:before {
  border-color: #bdc3c7;
}
.pretty .state.p-is-hover, .pretty .state.p-is-indeterminate {
  display: none;
}

@keyframes zoom {
  0% {
    opacity: 0;
    transform: scale(0);
  }
}
@keyframes tada {
  0% {
    animation-timing-function: ease-in;
    opacity: 0;
    transform: scale(7);
  }
  38% {
    animation-timing-function: ease-out;
    opacity: 1;
    transform: scale(1);
  }
  55% {
    animation-timing-function: ease-in;
    transform: scale(1.5);
  }
  72% {
    animation-timing-function: ease-out;
    transform: scale(1);
  }
  81% {
    animation-timing-function: ease-in;
    transform: scale(1.24);
  }
  89% {
    animation-timing-function: ease-out;
    transform: scale(1);
  }
  95% {
    animation-timing-function: ease-in;
    transform: scale(1.04);
  }
  100% {
    animation-timing-function: ease-out;
    transform: scale(1);
  }
}
@keyframes jelly {
  0% {
    transform: scale3d(1, 1, 1);
  }
  30% {
    transform: scale3d(0.75, 1.25, 1);
  }
  40% {
    transform: scale3d(1.25, 0.75, 1);
  }
  50% {
    transform: scale3d(0.85, 1.15, 1);
  }
  65% {
    transform: scale3d(1.05, 0.95, 1);
  }
  75% {
    transform: scale3d(0.95, 1.05, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}
@keyframes rotate {
  0% {
    opacity: 0;
    transform: translateZ(-200px) rotate(-45deg);
  }
  100% {
    opacity: 1;
    transform: translateZ(0) rotate(0);
  }
}
@keyframes pulse {
  0% {
    box-shadow: 0px 0px 0px 0px #bdc3c7;
  }
  100% {
    box-shadow: 0px 0px 0px 1.5em rgba(189, 195, 199, 0);
  }
}
.pretty.p-default.p-fill .state label:after {
  transform: scale(1);
}

.pretty.p-default .state label:after {
  transform: scale(0.6);
}
.pretty.p-default input:checked ~ .state label:after {
  background-color: #bdc3c7 !important;
}

.pretty.p-default.p-thick .state label:before, .pretty.p-default.p-thick .state label:after {
  border-width: calc(1em / 7);
}
.pretty.p-default.p-thick .state label:after {
  transform: scale(0.4) !important;
}

.pretty.p-icon .state .icon {
  position: absolute;
  font-size: 1em;
  width: calc(1em + 2px);
  height: calc(1em + 2px);
  left: 0;
  z-index: 1;
  text-align: center;
  line-height: normal;
  top: calc((0% - (100% - 1em)) - 8%);
  border: 1px solid transparent;
  opacity: 0;
}
.pretty.p-icon .state .icon:before {
  margin: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  line-height: 1;
}
.pretty.p-icon input:checked ~ .state .icon {
  opacity: 1;
}
.pretty.p-icon input:checked ~ .state label:before {
  border-color: #5a656b;
}

.pretty.p-svg .state .svg {
  position: absolute;
  font-size: 1em;
  width: calc(1em + 2px);
  height: calc(1em + 2px);
  left: 0;
  z-index: 1;
  text-align: center;
  line-height: normal;
  top: calc((0% - (100% - 1em)) - 8%);
  border: 1px solid transparent;
  opacity: 0;
}
.pretty.p-svg .state svg {
  margin: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  line-height: 1;
}
.pretty.p-svg input:checked ~ .state .svg {
  opacity: 1;
}

.pretty.p-image .state img {
  opacity: 0;
  position: absolute;
  width: calc(1em + 2px);
  height: calc(1em + 2px);
  top: 0;
  top: calc((0% - (100% - 1em)) - 8%);
  left: 0;
  z-index: 0;
  text-align: center;
  line-height: normal;
  transform: scale(0.8);
}
.pretty.p-image input:checked ~ .state img {
  opacity: 1;
}

.pretty.p-switch input {
  min-width: 2em;
}
.pretty.p-switch .state {
  position: relative;
}
.pretty.p-switch .state:before {
  content: "";
  border: 1px solid #bdc3c7;
  border-radius: 60px;
  width: 2em;
  box-sizing: unset;
  height: calc(1em + 2px);
  position: absolute;
  top: 0;
  top: calc((0% - (100% - 1em)) - 16%);
  z-index: 0;
  transition: all 0.5s ease;
}
.pretty.p-switch .state label {
  text-indent: 2.5em;
}
.pretty.p-switch .state label:before, .pretty.p-switch .state label:after {
  transition: all 0.5s ease;
  border-radius: 100%;
  left: 0;
  border-color: transparent;
  transform: scale(0.8);
}
.pretty.p-switch .state label:after {
  background-color: #bdc3c7 !important;
}
.pretty.p-switch input:checked ~ .state:before {
  border-color: #5a656b;
}
.pretty.p-switch input:checked ~ .state label:before {
  opacity: 0;
}
.pretty.p-switch input:checked ~ .state label:after {
  background-color: #5a656b !important;
  left: 1em;
}

.pretty.p-switch.p-fill input:checked ~ .state:before {
  border-color: #5a656b;
  background-color: #5a656b !important;
}
.pretty.p-switch.p-fill input:checked ~ .state label:before {
  opacity: 0;
}
.pretty.p-switch.p-fill input:checked ~ .state label:after {
  background-color: #fff !important;
  left: 1em;
}

.pretty.p-switch.p-slim .state:before {
  height: 0.1em;
  background: #bdc3c7 !important;
  top: calc(50% - 0.1em);
}
.pretty.p-switch.p-slim input:checked ~ .state:before {
  border-color: #5a656b;
  background-color: #5a656b !important;
}

.pretty.p-has-hover input:hover ~ .state:not(.p-is-hover) {
  display: none;
}
.pretty.p-has-hover input:hover ~ .state.p-is-hover {
  display: block;
}
.pretty.p-has-hover input:hover ~ .state.p-is-hover .icon {
  display: block;
}

.pretty.p-has-focus input:focus ~ .state label:before {
  box-shadow: 0px 0px 3px 0px #bdc3c7;
}

.pretty.p-has-indeterminate input[type=checkbox]:indeterminate ~ .state:not(.p-is-indeterminate) {
  display: none;
}
.pretty.p-has-indeterminate input[type=checkbox]:indeterminate ~ .state.p-is-indeterminate {
  display: block;
}
.pretty.p-has-indeterminate input[type=checkbox]:indeterminate ~ .state.p-is-indeterminate .icon {
  display: block;
  opacity: 1;
}

.pretty.p-toggle .state.p-on {
  opacity: 0;
  display: none;
}
.pretty.p-toggle .state.p-off,
.pretty.p-toggle .state .icon,
.pretty.p-toggle .state .svg,
.pretty.p-toggle .state img {
  opacity: 1;
  display: inherit;
}
.pretty.p-toggle .state.p-off .icon {
  color: #bdc3c7;
}
.pretty.p-toggle input:checked ~ .state.p-on {
  opacity: 1;
  display: inherit;
}
.pretty.p-toggle input:checked ~ .state.p-off {
  opacity: 0;
  display: none;
}

.pretty.p-plain input:checked ~ .state label:before, .pretty.p-plain.p-toggle .state label:before {
  content: none;
}
.pretty.p-plain.p-plain .icon {
  transform: scale(1.1);
}

.pretty.p-round .state label:before, .pretty.p-round .state label:after {
  border-radius: 100%;
}
.pretty.p-round.p-icon .state .icon {
  border-radius: 100%;
  overflow: hidden;
}
.pretty.p-round.p-icon .state .icon:before {
  transform: scale(0.8);
}

.pretty.p-curve .state label:before, .pretty.p-curve .state label:after {
  border-radius: 20%;
}

.pretty.p-smooth label:before,
.pretty.p-smooth label:after,
.pretty.p-smooth .icon,
.pretty.p-smooth .svg {
  transition: all 0.5s ease;
}
.pretty.p-smooth input:checked + .state label:after {
  transition: all 0.3s ease;
}
.pretty.p-smooth input:checked + .state .icon,
.pretty.p-smooth input:checked + .state .svg,
.pretty.p-smooth input:checked + .state img {
  animation: zoom 0.2s ease;
}
.pretty.p-smooth.p-default input:checked + .state label:after {
  animation: zoom 0.2s ease;
}
.pretty.p-smooth.p-plain input:checked + .state label:before {
  content: "";
  transform: scale(0);
  transition: all 0.5s ease;
}

.pretty.p-tada:not(.p-default) input:checked + .state .icon,
.pretty.p-tada:not(.p-default) input:checked + .state .svg,
.pretty.p-tada:not(.p-default) input:checked + .state img,
.pretty.p-tada:not(.p-default) input:checked + .state label:before,
.pretty.p-tada:not(.p-default) input:checked + .state label:after {
  animation: tada 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1 alternate;
  opacity: 1;
}

.pretty.p-jelly:not(.p-default) input:checked + .state .icon,
.pretty.p-jelly:not(.p-default) input:checked + .state .svg,
.pretty.p-jelly:not(.p-default) input:checked + .state img,
.pretty.p-jelly:not(.p-default) input:checked + .state label:before,
.pretty.p-jelly:not(.p-default) input:checked + .state label:after {
  animation: jelly 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 1;
}
.pretty.p-jelly:not(.p-default) input:checked + .state label:before {
  border-color: transparent;
}

.pretty.p-rotate:not(.p-default) input:checked ~ .state .icon,
.pretty.p-rotate:not(.p-default) input:checked ~ .state .svg,
.pretty.p-rotate:not(.p-default) input:checked ~ .state img,
.pretty.p-rotate:not(.p-default) input:checked ~ .state label:before,
.pretty.p-rotate:not(.p-default) input:checked ~ .state label:after {
  animation: rotate 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 1;
}
.pretty.p-rotate:not(.p-default) input:checked ~ .state label:before {
  border-color: transparent;
}

.pretty.p-pulse:not(.p-switch) input:checked ~ .state label:before {
  animation: pulse 1s;
}

.pretty input[disabled] {
  cursor: not-allowed;
  display: none;
}
.pretty input[disabled] ~ * {
  opacity: 0.5;
}

.pretty.p-locked input {
  display: none;
  cursor: not-allowed;
}

.pretty input:checked ~ .state.p-primary label:after, .pretty.p-toggle .state.p-primary label:after {
  background-color: #428bca !important;
}
.pretty input:checked ~ .state.p-primary .icon,
.pretty input:checked ~ .state.p-primary .svg, .pretty.p-toggle .state.p-primary .icon,
.pretty.p-toggle .state.p-primary .svg {
  color: #fff;
  stroke: #fff;
}
.pretty input:checked ~ .state.p-primary-o label:before, .pretty.p-toggle .state.p-primary-o label:before {
  border-color: #428bca;
}
.pretty input:checked ~ .state.p-primary-o label:after, .pretty.p-toggle .state.p-primary-o label:after {
  background-color: transparent;
}
.pretty input:checked ~ .state.p-primary-o .icon,
.pretty input:checked ~ .state.p-primary-o .svg,
.pretty input:checked ~ .state.p-primary-o svg, .pretty.p-toggle .state.p-primary-o .icon,
.pretty.p-toggle .state.p-primary-o .svg,
.pretty.p-toggle .state.p-primary-o svg {
  color: #428bca;
  stroke: #428bca;
}
.pretty.p-default:not(.p-fill) input:checked ~ .state.p-primary-o label:after {
  background-color: #428bca !important;
}
.pretty.p-switch input:checked ~ .state.p-primary:before {
  border-color: #428bca;
}
.pretty.p-switch.p-fill input:checked ~ .state.p-primary:before {
  background-color: #428bca !important;
}
.pretty.p-switch.p-slim input:checked ~ .state.p-primary:before {
  border-color: #245682;
  background-color: #245682 !important;
}
.pretty input:checked ~ .state.p-info label:after, .pretty.p-toggle .state.p-info label:after {
  background-color: #5bc0de !important;
}
.pretty input:checked ~ .state.p-info .icon,
.pretty input:checked ~ .state.p-info .svg, .pretty.p-toggle .state.p-info .icon,
.pretty.p-toggle .state.p-info .svg {
  color: #fff;
  stroke: #fff;
}
.pretty input:checked ~ .state.p-info-o label:before, .pretty.p-toggle .state.p-info-o label:before {
  border-color: #5bc0de;
}
.pretty input:checked ~ .state.p-info-o label:after, .pretty.p-toggle .state.p-info-o label:after {
  background-color: transparent;
}
.pretty input:checked ~ .state.p-info-o .icon,
.pretty input:checked ~ .state.p-info-o .svg,
.pretty input:checked ~ .state.p-info-o svg, .pretty.p-toggle .state.p-info-o .icon,
.pretty.p-toggle .state.p-info-o .svg,
.pretty.p-toggle .state.p-info-o svg {
  color: #5bc0de;
  stroke: #5bc0de;
}
.pretty.p-default:not(.p-fill) input:checked ~ .state.p-info-o label:after {
  background-color: #5bc0de !important;
}
.pretty.p-switch input:checked ~ .state.p-info:before {
  border-color: #5bc0de;
}
.pretty.p-switch.p-fill input:checked ~ .state.p-info:before {
  background-color: #5bc0de !important;
}
.pretty.p-switch.p-slim input:checked ~ .state.p-info:before {
  border-color: #2390b0;
  background-color: #2390b0 !important;
}
.pretty input:checked ~ .state.p-success label:after, .pretty.p-toggle .state.p-success label:after {
  background-color: #5cb85c !important;
}
.pretty input:checked ~ .state.p-success .icon,
.pretty input:checked ~ .state.p-success .svg, .pretty.p-toggle .state.p-success .icon,
.pretty.p-toggle .state.p-success .svg {
  color: #fff;
  stroke: #fff;
}
.pretty input:checked ~ .state.p-success-o label:before, .pretty.p-toggle .state.p-success-o label:before {
  border-color: #5cb85c;
}
.pretty input:checked ~ .state.p-success-o label:after, .pretty.p-toggle .state.p-success-o label:after {
  background-color: transparent;
}
.pretty input:checked ~ .state.p-success-o .icon,
.pretty input:checked ~ .state.p-success-o .svg,
.pretty input:checked ~ .state.p-success-o svg, .pretty.p-toggle .state.p-success-o .icon,
.pretty.p-toggle .state.p-success-o .svg,
.pretty.p-toggle .state.p-success-o svg {
  color: #5cb85c;
  stroke: #5cb85c;
}
.pretty.p-default:not(.p-fill) input:checked ~ .state.p-success-o label:after {
  background-color: #5cb85c !important;
}
.pretty.p-switch input:checked ~ .state.p-success:before {
  border-color: #5cb85c;
}
.pretty.p-switch.p-fill input:checked ~ .state.p-success:before {
  background-color: #5cb85c !important;
}
.pretty.p-switch.p-slim input:checked ~ .state.p-success:before {
  border-color: #357935;
  background-color: #357935 !important;
}
.pretty input:checked ~ .state.p-warning label:after, .pretty.p-toggle .state.p-warning label:after {
  background-color: #f0ad4e !important;
}
.pretty input:checked ~ .state.p-warning .icon,
.pretty input:checked ~ .state.p-warning .svg, .pretty.p-toggle .state.p-warning .icon,
.pretty.p-toggle .state.p-warning .svg {
  color: #fff;
  stroke: #fff;
}
.pretty input:checked ~ .state.p-warning-o label:before, .pretty.p-toggle .state.p-warning-o label:before {
  border-color: #f0ad4e;
}
.pretty input:checked ~ .state.p-warning-o label:after, .pretty.p-toggle .state.p-warning-o label:after {
  background-color: transparent;
}
.pretty input:checked ~ .state.p-warning-o .icon,
.pretty input:checked ~ .state.p-warning-o .svg,
.pretty input:checked ~ .state.p-warning-o svg, .pretty.p-toggle .state.p-warning-o .icon,
.pretty.p-toggle .state.p-warning-o .svg,
.pretty.p-toggle .state.p-warning-o svg {
  color: #f0ad4e;
  stroke: #f0ad4e;
}
.pretty.p-default:not(.p-fill) input:checked ~ .state.p-warning-o label:after {
  background-color: #f0ad4e !important;
}
.pretty.p-switch input:checked ~ .state.p-warning:before {
  border-color: #f0ad4e;
}
.pretty.p-switch.p-fill input:checked ~ .state.p-warning:before {
  background-color: #f0ad4e !important;
}
.pretty.p-switch.p-slim input:checked ~ .state.p-warning:before {
  border-color: #c77c11;
  background-color: #c77c11 !important;
}
.pretty input:checked ~ .state.p-danger label:after, .pretty.p-toggle .state.p-danger label:after {
  background-color: #d9534f !important;
}
.pretty input:checked ~ .state.p-danger .icon,
.pretty input:checked ~ .state.p-danger .svg, .pretty.p-toggle .state.p-danger .icon,
.pretty.p-toggle .state.p-danger .svg {
  color: #fff;
  stroke: #fff;
}
.pretty input:checked ~ .state.p-danger-o label:before, .pretty.p-toggle .state.p-danger-o label:before {
  border-color: #d9534f;
}
.pretty input:checked ~ .state.p-danger-o label:after, .pretty.p-toggle .state.p-danger-o label:after {
  background-color: transparent;
}
.pretty input:checked ~ .state.p-danger-o .icon,
.pretty input:checked ~ .state.p-danger-o .svg,
.pretty input:checked ~ .state.p-danger-o svg, .pretty.p-toggle .state.p-danger-o .icon,
.pretty.p-toggle .state.p-danger-o .svg,
.pretty.p-toggle .state.p-danger-o svg {
  color: #d9534f;
  stroke: #d9534f;
}
.pretty.p-default:not(.p-fill) input:checked ~ .state.p-danger-o label:after {
  background-color: #d9534f !important;
}
.pretty.p-switch input:checked ~ .state.p-danger:before {
  border-color: #d9534f;
}
.pretty.p-switch.p-fill input:checked ~ .state.p-danger:before {
  background-color: #d9534f !important;
}
.pretty.p-switch.p-slim input:checked ~ .state.p-danger:before {
  border-color: #a02622;
  background-color: #a02622 !important;
}

.pretty.p-bigger label:before,
.pretty.p-bigger label:after,
.pretty.p-bigger .icon,
.pretty.p-bigger .svg,
.pretty.p-bigger .img {
  font-size: 1.2em !important;
  top: calc((0% - (100% - 1em)) - 35%) !important;
}
.pretty.p-bigger label {
  text-indent: 1.7em;
}

@media print {
  .pretty .state:before,
.pretty .state label:before,
.pretty .state label:after,
.pretty .state .icon {
    color-adjust: exact;
    /* stylelint-disable */
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}
#wrapper {
  width: 100%;
  min-width: 300px;
  max-width: 480px;
  margin: 0 auto;
}

#header {
  float: left;
  height: 75px;
  width: 100%;
  text-transform: uppercase;
  vertical-align: center;
}

#contentliquid {
  float: left;
  width: 100%;
}

#content {
  width: 100%;
}

#footer {
  height: 40px;
  width: 100%;
  clear: both;
}

* {
  font-family: "Montserrat", sans-serif;
  font-size: 25px;
}

body {
  background-image: url("/assets/img/bg.webp");
  background-color: black;
  background-size: auto;
  background-position: top center;
  background-attachment: fixed;
  color: white;
  text-align: center;
  margin: 10px;
}

input {
  background-color: transparent;
  color: white;
  padding: 0.3em;
  margin-bottom: 10px;
  border: solid 1px #5aa6f3;
  box-sizing: border-box;
  width: 100%;
}

button {
  background-color: transparent;
  border: solid 1px white;
  padding: 0.3em;
  color: white;
  text-transform: uppercase;
  width: 100%;
  margin-bottom: 10px;
  transition: 0.5s;
}

button:hover {
  background-color: white;
  color: black;
}

button .button-c1 {
  background-color: rgba(151, 151, 151, 0.2);
}

.button-c2 {
  background-color: rgba(151, 151, 151, 0.4);
}

.button-c3 {
  background-color: rgba(151, 151, 151, 0.6);
}